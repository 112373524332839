import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import {
  downloadUtilityReportForCity,
  downloadUtilityReportForCityError,
  downloadUtilityReportForFacility,
  downloadUtilityReportForFacilityError,
  downloadUtilityReportForGroup,
  downloadUtilityReportForGroupError,
  getUtilityReportDataForCity,
  getUtilityReportDataForCityError,
  getUtilityReportDataForCitySuccess,
  getUtilityReportDataForFacility,
  getUtilityReportDataForFacilityError,
  getUtilityReportDataForFacilitySuccess,
  getUtilityReportDataForGroup,
  getUtilityReportDataForGroupError,
  getUtilityReportDataForGroupSuccess,
} from '../actions/utility-report.actions';
import { UtilityReportService } from '../../service/utility/utility-report.service';
import { saveAs } from 'file-saver';
import { State } from '../../../reducers';
import { Store } from '@ngrx/store';
import { selectFacilities, selectFacilityGroups } from '../selectors/facility.selector';

@Injectable()
export class UtilityReportEffects {
  downloadUtilityReportForFacilityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadUtilityReportForFacility),
      concatLatestFrom(() => this.store.select(selectFacilities)),
      switchMap(([{ facilityId, startDate, endDate, utilityType }, facilities]) => {
        const facilityName = facilities.find(facility => facility.id === facilityId)?.name ?? 'facility';
        return this.utilityReportService.downloadFacilityUtilityReport(facilityId, startDate, endDate, utilityType).pipe(
          tap(content => {
            const blob = new Blob([content], { type: 'application/pdf' });
            saveAs(blob, `${utilityType} report - ${facilityName}.pdf`);
          }),
          catchError(error => of(downloadUtilityReportForFacilityError({ error }))),
        );
      }),
    );
  });

  downloadUtilityReportForGroupEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadUtilityReportForGroup),
      concatLatestFrom(() => this.store.select(selectFacilityGroups)),
      switchMap(([{ facilityGroupId, startDate, endDate, utilityType }, groups]) => {
        const facilityGroupName = groups.find(group => group.id === facilityGroupId)?.name ?? 'group';
        return this.utilityReportService.downloadFacilityGroupUtilityReport(facilityGroupId, startDate, endDate, utilityType).pipe(
          tap(content => {
            const blob = new Blob([content], { type: 'application/pdf' });
            saveAs(blob, `${utilityType} report - ${facilityGroupName}.pdf`);
          }),
          catchError(error => of(downloadUtilityReportForGroupError({ error }))),
        );
      }),
    );
  });

  downloadUtilityReportForCityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadUtilityReportForCity),
      switchMap(({ startDate, endDate, utilityType }) =>
        this.utilityReportService.downloadCityUtilityReport(startDate, endDate, utilityType).pipe(
          tap(content => {
            const blob = new Blob([content], { type: 'application/pdf' });
            saveAs(blob, `${utilityType} report - city.pdf`);
          }),
          catchError(error => of(downloadUtilityReportForCityError({ error }))),
        ),
      ),
    );
  });

  getUtilityReportDataForFacilityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getUtilityReportDataForFacility),
      switchMap(({ facilityId, startDate, endDate }) => {
        return this.utilityReportService.getFacilityUtilityReportData(facilityId, startDate, endDate).pipe(
          map(data => getUtilityReportDataForFacilitySuccess({ data })),
          catchError(error => of(getUtilityReportDataForFacilityError({ error }))),
        );
      }),
    );
  });

  getUtilityReportDataForGroupEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getUtilityReportDataForGroup),
      switchMap(({ facilityGroupId, startDate, endDate }) => {
        return this.utilityReportService.getFacilityGroupUtilityReportData(facilityGroupId, startDate, endDate).pipe(
          map(data => getUtilityReportDataForGroupSuccess({ data })),
          catchError(error => of(getUtilityReportDataForGroupError({ error }))),
        );
      }),
    );
  });

  getUtilityReportDataForCityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getUtilityReportDataForCity),
      switchMap(({ startDate, endDate }) =>
        this.utilityReportService.getCityUtilityReportData(startDate, endDate).pipe(
          map(data => getUtilityReportDataForCitySuccess({ data })),
          catchError(error => of(getUtilityReportDataForCityError({ error }))),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private utilityReportService: UtilityReportService,
    private store: Store<State>,
  ) {}
}
