import { createReducer, on } from '@ngrx/store';
import {
  initLoginForgotPassword,
  initLoginSignIn, loadUserAuthenticatedError,
  loadUserAuthenticatedSuccess,
  postForgotPassword,
  postForgotPasswordError,
  postForgotPasswordSuccess,
  postLogin,
  postLoginError,
  postLoginSuccess,
} from '../actions/authentication.action';

export default interface AuthenticationState {
  isLoginLoading: boolean;
  isLoginError: boolean;
  isForgotPasswordLoading: boolean;
  isForgotPasswordSuccess: boolean;
  userAuthenticated?: any;
}

export const initialState: AuthenticationState = {
  isLoginLoading: false,
  isLoginError: false,
  isForgotPasswordLoading: false,
  isForgotPasswordSuccess: false,
  userAuthenticated: undefined,
};

export const reducer = createReducer(
  initialState,

  on(initLoginSignIn, (state): AuthenticationState => ({ ...state, isLoginError: false })),
  on(initLoginForgotPassword, (state): AuthenticationState => ({ ...state, isForgotPasswordSuccess: false })),

  on(postLogin, (state): AuthenticationState => ({ ...state, isLoginLoading: true, isLoginError: false })),
  on(postLoginSuccess, (state, { userAuthenticated }): AuthenticationState => ({ ...state, isLoginLoading: false, isLoginError: false, userAuthenticated })),
  on(postLoginError, (state): AuthenticationState => ({ ...state, isLoginLoading: false, isLoginError: true })),

  on(postForgotPassword, (state): AuthenticationState => ({ ...state, isForgotPasswordLoading: true, isForgotPasswordSuccess: false })),
  on(postForgotPasswordSuccess, (state): AuthenticationState => ({ ...state, isForgotPasswordLoading: false, isForgotPasswordSuccess: true })),
  on(postForgotPasswordError, (state): AuthenticationState => ({ ...state, isForgotPasswordLoading: false, isForgotPasswordSuccess: true })),

  on(loadUserAuthenticatedSuccess, (state, { userAuthenticated }): AuthenticationState => ({ ...state, userAuthenticated })),
  on(loadUserAuthenticatedError, (state): AuthenticationState => ({ ...state, userAuthenticated: undefined }))
);
