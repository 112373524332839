export const getFormattedUrl = (url: string): string => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  } else {
    return `https://${url}`;
  }
};

export const getSignInUrl = () => '/auth/login/sign-in';

export const getForgotPasswordUrl = () => '/auth/login/forgot-password';

export const getDashboardlUrl = () => '/dashboard';

export const getDashboardPortalUrl = (facilityGroupId?: number) => `/portal/dashboard/group/${facilityGroupId}`;

export const getAdvancedAlertingUrl = () => '/portal/alerting/advanced';

export const getBasicAlertingUrl = () => '/portal/alerting/basic';

export const getAllGroupsUrl = () => '/portal/groups/all';

export const getDetailedReportUrl = () => '/portal/report/detailed';
export const getSummaryReportUrl = () => '/portal/report/summary';

export const getGroupsUrl = (typeOfReport?: string, groupName?: string, facilityGroupId?: number, facilityId?: number) =>
  `/portal/groups/${typeOfReport?.replace(/\s+/g, '').toLowerCase()}/${groupName}/${facilityGroupId}/facilityId/${facilityId}`;
