import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { from } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private http: HttpClient, private oAuthService: OAuthService) {
  }

  login(username: string, password: string) {
    return from(this.oAuthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(username, password));
  }

  askForPasswordChange(email: string) {
    const body = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: environment.authClientId,
      email,
      connection: 'Username-Password-Authentication',
    };

    return this.http.post<string>(`${environment.authUrl}/dbconnections/change_password`, body);
  }

  userAuthenticated() {
    const rolesClaim = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/roles';
    const claims = this.oAuthService.getIdentityClaims();
    if (claims && claims[rolesClaim]) {
      const roles: string[] = claims[rolesClaim];
      return {
        ...claims,
        roles,
      };
    }
    return null;
  }
}
