import { createAction, props } from '@ngrx/store';
import { Facility } from '../../model/facility.model';
import { FacilityGroup } from 'brain-data/model/facility-group.model';
import { FacilityPerformanceYearly, FacilitySolarPerformance } from 'brain-data/model/facility-performance.model';
import { AlertNotification } from 'brain-data/model/alert.model';

// FACILITIES

export const loadFacilityGroups = createAction('[Facility Groups] load');
export const loadFacilityGroupsSuccess = createAction('[Facility Groups] load success', props<{ facilityGroups: FacilityGroup[] }>());
export const loadFacilityGroupsError = createAction('[Facility Groups] load error', props<{ error: Error }>());

export const loadFacilities = createAction('[Facilities] load facilities');
export const loadFacilitiesSuccess = createAction('[Facilities] load facilities success', props<{ facilities: Facility[] }>());
export const loadFacilitiesError = createAction('[App] load facilities error', props<{ error: Error }>());

export const updateFacilities = createAction('[Facilities] update facilities', props<{ facilities: Facility[] }>());

export const loadFacilityById = createAction('[Facilities] load facility by id', props<{ facilityId: number }>());
export const loadFacilityByIdSuccess = createAction('[Facilities] load facility by id success', props<{ facility: Facility }>());
export const loadFacilityByIdError = createAction('[Facilities] load facility by id error', props<{ error: Error }>());

export const loadFacilityGroupEuiForYearAndFacilityGroupId = createAction(
  '[Facility Group] Load FacilityGroup Eui for year and facility group id',
  props<{ euiYear: number; facilityGroupId: number }>(),
);
export const loadFacilityGroupEuiForYearAndFacilityGroupIdSuccess = createAction(
  '[Facility Group] Load FacilityGroup Eui for year and facility group id Success',
  props<{ facilityGroup: FacilityGroup }>(),
);
export const loadFacilityGroupEuiForYearAndFacilityGroupIdError = createAction(
  '[Facility Group] Load FacilityGroup Eui for year and facility group id Error',
  props<{ error: Error }>(),
);

export const loadFacilityPerformancesBuildingByFacilityGroupId = createAction(
  '[Facility Performances] Load FacilityPerformance Building for FacilityGroupId',
  props<{ facilityGroupId: number }>(),
);
export const loadFacilityPerformancesBuildingByFacilityGroupIdSuccess = createAction(
  '[Facility Performances] Load FacilityPerformance Building for FacilityGroupId Success',
  props<{ facilityGroupPerformance: FacilityPerformanceYearly[] }>(),
);
export const loadFacilityPerformancesBuildingByFacilityGroupIdError = createAction(
  '[Facility Performances] Load FacilityPerformanceBuilding for FacilityGroupId Error',
  props<{ error: Error }>(),
);

export const loadEuiAndFacilityPerformancesBuildingByFacilityGroupId = createAction(
  '[Facility Performances] Load EuiAndFacilityPerformance Building for FacilityGroupId',
  props<{ euiYear: number; facilityGroupId: number }>(),
);
export const loadEuiAndFacilityPerformancesBuildingByFacilityGroupIdSuccess = createAction(
  '[Facility Performances] Load EuiAndFacilityPerformance Building for FacilityGroupId Success',
  props<{ facilityGroup: FacilityGroup; facilityGroupPerformance: FacilityPerformanceYearly[] }>(),
);
export const loadEuiAndFacilityPerformancesBuildingByFacilityGroupIdError = createAction(
  '[Facility Performances] Load EuiAndFacilityPerformanceBuilding for FacilityGroupId Error',
  props<{ error: Error }>(),
);

export const loadFacilityPerformanceBuildingByFacilityId = createAction(
  '[Facility Performances] Load FacilityPerformance Building for FacilityId',
  props<{ facilityId: number }>(),
);
export const loadFacilityPerformanceBuildingByFacilityIdSuccess = createAction(
  '[Facility Performances] Load FacilityPerformance Building for FacilityId Success',
  props<{ facilityPerformance: FacilityPerformanceYearly }>(),
);
export const loadFacilityPerformanceBuildingByFacilityIdError = createAction(
  '[Facility Performances] Load FacilityPerformance Building for FacilityId Error',
  props<{ error: Error }>(),
);

export const loadFacilityPerformanceSolarByFacilityId = createAction(
  '[Facility Performances] Load FacilityPerformance Solar for FacilityId',
  props<{ facilityId: number }>(),
);
export const loadFacilityPerformanceSolarByFacilityIdSuccess = createAction(
  '[Facility Performances] Load FacilityPerformance Solar for FacilityId Success',
  props<{ facilitySolarPerformance: FacilitySolarPerformance[] }>(),
);
export const loadFacilityPerformanceSolarByFacilityIdError = createAction(
  '[Facility Performances] Load FacilityPerformance Solar for FacilityId Error',
  props<{ error: Error }>(),
);

export const loadFacilityPerformanceSolarByFacilityGroupId = createAction(
  '[Facility Performances] Load FacilityPerformance Solar for FacilityGroupId',
  props<{ facilityGroupId: number }>(),
);
export const loadFacilityPerformanceSolarByFacilityGroupIdSuccess = createAction(
  '[Facility Performances] Load FacilityPerformance Solar for FacilityGroupId Success',
  props<{ facilitySolarPerformance: FacilitySolarPerformance[] }>(),
);
export const loadFacilityPerformanceSolarByFacilityGroupIdError = createAction(
  '[Facility Performances] Load FacilityPerformance Solar for FacilityGroupId Error',
  props<{ error: Error }>(),
);

//Combined Actions

export const loadFacilitiesAtStartup = createAction('[Data] Init Facilities at startup');
export const loadFacilitiesAtStartupSuccess = createAction(
  '[Data] Init Facilities at startup Success',
  props<{ facilities: Facility[]; facilityGroups: FacilityGroup[] }>(),
);
export const loadFacilitiesAtStartupError = createAction('[Data] Init Facilities at startup error', props<{ error: Error }>());

export const setCurrentFacilityGroup = createAction('[Facility Group] set current facility group', props<{ facilityGroup: FacilityGroup }>());

export const clearCurrentFacilityGroup = createAction('[Facility Group] clear current facility group');

export const setCurrentGroupAndFacilityByIds = createAction(
  '[Facility] set current group and facility by id',
  props<{ facilityGroupId: number; facilityId: number }>(),
);

export const setCurrentFacilityGroupById = createAction('[Facility] set current facility group by id', props<{ facilityGroupId: number }>());
export const setCurrentFacilityById = createAction('[Facility] set current facility by id', props<{ facilityId: number }>());

export const updateFacilityAlertWithSnooze = createAction(
  '[Facility] Update Alert With Snooze and Optional Notification',
  props<{ alertId: number; snoozedUntil: string; alertNotification?: AlertNotification }>(),
);
