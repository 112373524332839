import { createAction, props } from '@ngrx/store';
import { UtilityType } from '../../../constants/utility.constant';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilityReportData } from '../../model/report.model';

export const downloadUtilityReportForFacility = createAction(
  '[Utility Report] download utility report for facility',
  props<{ facilityId: number; startDate?: string; endDate?: string; utilityType: UtilityType }>()
);

export const downloadUtilityReportForFacilityError = createAction(
  '[Utility Report] download utility report for facility error',
  props<{ error: HttpErrorResponse }>()
);
export const downloadUtilityReportForGroup = createAction(
  '[Utility Report] download utility report for group',
  props<{ facilityGroupId: number; startDate?: string; endDate?: string; utilityType: UtilityType }>()
);

export const downloadUtilityReportForGroupError = createAction(
  '[Utility Report] download utility report for group error',
  props<{ error: HttpErrorResponse }>()
);
export const downloadUtilityReportForCity = createAction(
  '[Utility Report] download utility report for city',
  props<{ startDate?: string; endDate?: string; utilityType: UtilityType }>()
);

export const downloadUtilityReportForCityError = createAction(
  '[Utility Report] download utility report for city error',
  props<{ error: HttpErrorResponse }>()
);

export const getUtilityReportDataForFacility = createAction(
  '[Utility Report] get utility report for facility',
  props<{ facilityId: number; startDate: string; endDate: string }>()
);

export const getUtilityReportDataForFacilitySuccess = createAction(
  '[Utility Report] get utility report for facility success',
  props<{ data: UtilityReportData }>()
);

export const getUtilityReportDataForFacilityError = createAction(
  '[Utility Report] download utility report for facility error',
  props<{ error: HttpErrorResponse }>()
);
export const getUtilityReportDataForGroup = createAction(
  '[Utility Report] get utility report for group',
  props<{ facilityGroupId: number; startDate: string; endDate: string }>()
);

export const getUtilityReportDataForGroupSuccess = createAction(
  '[Utility Report] get utility report for group success',
  props<{ data: UtilityReportData }>()
);

export const getUtilityReportDataForGroupError = createAction(
  '[Utility Report] get utility report for group error',
  props<{ error: HttpErrorResponse }>()
);
export const getUtilityReportDataForCity = createAction(
  '[Utility Report] get utility report for city',
  props<{ startDate: string; endDate: string }>()
);

export const getUtilityReportDataForCitySuccess = createAction(
  '[Utility Report] get utility report for city success',
  props<{ data: UtilityReportData }>()
);

export const getUtilityReportDataForCityError = createAction(
  '[Utility Report] get utility report for city error',
  props<{ error: HttpErrorResponse }>()
);
