import { Injectable } from '@angular/core';
import { CUSTOMER_NAME } from 'constants/customer.constant';
import { TENANT } from 'constants/tenant.constant';

const PRODUCTION_TENANTS = [TENANT.coa];

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private readonly tenant: string;

  constructor() {
    this.tenant = this.getSubDomainFromUrl() ?? 'local';
  }

  getSubDomainFromUrl = (): string | undefined => {
    const urlParts = window.location.href.match(/^(https?:\/\/)?([^\/]+)(\/.*)?$/);
    return urlParts?.[2]?.match(/^([^.]+)/)?.[1];
  };

  getLogoNameFromTenant(anotherPicture: boolean): string {
    const prefixLogo = 'logo';

    let logo =
      this.tenant === TENANT.coa
        ? `${prefixLogo}-${CUSTOMER_NAME.albuquerque}`
        : this.tenant === TENANT.demo
        ? `${prefixLogo}-${CUSTOMER_NAME.gridwatch}`
        : `${prefixLogo}-${CUSTOMER_NAME.gridwatch}`;

    logo += anotherPicture ? '-2' : '';

    return logo;
  }

  getTenant() {
    return this.tenant;
  }

  isProductionTenant = () => PRODUCTION_TENANTS.includes(this.getTenant());
}
