import { Injectable } from '@angular/core';

import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class AuthPortalGuard  {
  constructor(private oauthService: OAuthService) {}

  canActivate(): boolean {
    if (!this.oauthService.hasValidAccessToken()) {
      this.oauthService.logOut();
      return false;
    }
    return true;
  }
}
