import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import {
  loadUserAuthenticated,
  loadUserAuthenticatedError,
  loadUserAuthenticatedSuccess,
  postForgotPassword,
  postForgotPasswordError,
  postForgotPasswordSuccess,
  postLogin,
  postLoginError,
  postLoginSuccess,
} from '../actions/authentication.action';
import { AuthenticationService } from 'brain-data/service/auth/authentication.service';
import { getDashboardlUrl } from 'shared/services/utils/url/url.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationEffects {
  postLoginEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postLogin),
      switchMap(({ username, password }) => {
        return this.authenticationService.login(username, password).pipe(
          map(userAuthenticated => postLoginSuccess({ userAuthenticated })),
          tap(() => {
            void this.router.navigate([getDashboardlUrl()]);
          }),
          catchError(error => of(postLoginError({ error })))
        );
      })
    );
  });

  loadUserAuthenticatedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserAuthenticated),
      map(() => {
        const userAuthenticated = this.authenticationService.userAuthenticated();
        if (userAuthenticated) {
          return loadUserAuthenticatedSuccess({ userAuthenticated });
        }
        return loadUserAuthenticatedError({ error: new Error('no user') });
      })
    );
  });

  postForgotPasswordEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postForgotPassword),
      switchMap(({ email }) => {
        return this.authenticationService.askForPasswordChange(email).pipe(
          map(() => postForgotPasswordSuccess()),
          catchError(error => of(postForgotPasswordError({ error })))
        );
      })
    );
  });

  constructor(private router: Router, private actions$: Actions, private authenticationService: AuthenticationService) {
  }
}
