import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  snoozeAlert,
  snoozeAlertError,
  snoozeAlertSuccess,
  notifyAlert,
  notifyAlertError,
  notifyAlertSuccess,
  notifyAndSnoozeAlert,
  notifyAndSnoozeAlertSuccess,
  notifyAndSnoozeAlertError,
} from '../actions/alert.action';
import { AlertService } from 'brain-data/service/alert/alert.service';
import { updateFacilityAlertWithSnooze } from '../actions/facility.actions';

@Injectable()
export class AlertEffects {
  notifyEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notifyAlert),
      switchMap(({ alertId, alertNotification }) =>
        this.alertService.notify(alertId, alertNotification).pipe(
          map(() => notifyAlertSuccess()),
          catchError(error => of(notifyAlertError({ error }))),
        ),
      ),
    );
  });

  snoozeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(snoozeAlert),
      switchMap(({ alertId, days }) =>
        this.alertService.snooze(alertId, days).pipe(
          map(() => snoozeAlertSuccess({ alertId, days })),
          catchError(error => of(snoozeAlertError({ error }))),
        ),
      ),
    );
  });

  notifyAndsnoozeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notifyAndSnoozeAlert),
      switchMap(({ alertId, days, alertNotification }) =>
        this.alertService.notifyAndSnooze(alertId, days, alertNotification).pipe(
          map(() => notifyAndSnoozeAlertSuccess({ alertId, days, alertNotification })),
          catchError(error => of(notifyAndSnoozeAlertError({ error }))),
        ),
      ),
    );
  });

  updateAlertWithSnoozeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(snoozeAlertSuccess, notifyAndSnoozeAlertSuccess),
      map(action => {
        const snoozedUntil = new Date();

        debugger;

        const daysToAdd = Number(action.days);
        if (!isNaN(daysToAdd)) {
          snoozedUntil.setDate(snoozedUntil.getDate() + daysToAdd);
        }

        const hasNotification = 'alertNotification' in action;

        return updateFacilityAlertWithSnooze({
          alertId: action.alertId,
          snoozedUntil: snoozedUntil.toISOString().split('T')[0],
          ...(hasNotification && { alertNotification: action.alertNotification }),
        });
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
  ) {}
}
