import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  loadTotalUtilityContracts,
  loadTotalUtilityContractsError,
  loadTotalUtilityContractsSuccess,
  loadUtilityContractsWithFacilityUnassigned,
  loadUtilityContractsWithFacilityUnassignedError,
  loadUtilityContractsWithFacilityUnassignedSuccess,
  updateAndAssignFacilityToUtilityContract,
  updateAndAssignFacilityToUtilityContractSuccess,
} from '../actions/utility-contract.action';
import { UtilityContractService } from 'brain-data/service/utility/utility-contract.service';

@Injectable()
export class UtilityContractsEffects {
  loadTotalUtilityContractsWithFacilityUnassignedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilityContractsWithFacilityUnassigned),
      switchMap(() =>
        this.utilityContractService.getUtilityContractsWithFacilityUnassigned().pipe(
          map(utilityContracts => loadUtilityContractsWithFacilityUnassignedSuccess({ utilityContracts })),
          catchError(error => of(loadUtilityContractsWithFacilityUnassignedError({ error })))
        )
      )
    );
  });

  loadTotalUtilityContractsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadTotalUtilityContracts),
      switchMap(() =>
        this.utilityContractService.getTotalUtilityContracts().pipe(
          map(totalUtilityContracts => loadTotalUtilityContractsSuccess({ totalUtilityContracts })),
          catchError(error => of(loadTotalUtilityContractsError({ error })))
        )
      )
    );
  });

  updateAndAssignFacilityToUtilityContractEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateAndAssignFacilityToUtilityContract),
      switchMap(({ utilityContractNumber, facilityId }) =>
        this.utilityContractService.updateAndAssignFacilityToUtilityContract(utilityContractNumber, facilityId).pipe(
          map(() => updateAndAssignFacilityToUtilityContractSuccess()),
          catchError(error => of(loadTotalUtilityContractsError({ error })))
        )
      )
    );
  });

  constructor(private actions$: Actions, private utilityContractService: UtilityContractService) {}
}
