import { createReducer, on } from '@ngrx/store';
import { Facility } from '../../model/facility.model';
import {
  loadFacilitiesAtStartupSuccess,
  loadFacilities,
  loadFacilitiesSuccess,
  loadFacilityByIdSuccess,
  loadFacilityGroupsSuccess,
  loadFacilitiesAtStartup,
  loadFacilityById,
  loadFacilityGroupEuiForYearAndFacilityGroupIdSuccess,
  loadFacilityPerformanceBuildingByFacilityIdSuccess,
  loadFacilityPerformancesBuildingByFacilityGroupIdError,
  loadFacilityPerformanceBuildingByFacilityId,
  loadFacilityPerformancesBuildingByFacilityGroupIdSuccess,
  loadFacilityPerformancesBuildingByFacilityGroupId,
  loadFacilityPerformanceBuildingByFacilityIdError,
  loadFacilityPerformanceSolarByFacilityGroupId,
  loadFacilityPerformanceSolarByFacilityId,
  loadFacilityPerformanceSolarByFacilityGroupIdSuccess,
  loadFacilityPerformanceSolarByFacilityIdSuccess,
  loadFacilityPerformanceSolarByFacilityGroupIdError,
  loadFacilityPerformanceSolarByFacilityIdError,
  updateFacilities,
  setCurrentFacilityGroup,
  clearCurrentFacilityGroup,
  setCurrentFacilityById,
  setCurrentFacilityGroupById,
  setCurrentGroupAndFacilityByIds,
  updateFacilityAlertWithSnooze,
} from '../actions/facility.actions';
import { FacilityGroup } from '../../model/facility-group.model';
import { FacilityPerformanceYearly, FacilitySolarPerformance } from 'brain-data/model/facility-performance.model';

export default interface FacilityState {
  facilityGroups: FacilityGroup[];
  facilities: Facility[];
  facilityGroup?: FacilityGroup;
  facility?: Facility;
  facilityGroupEui?: number;
  facilityPerformanceLoading: boolean;
  facilityPerformance: FacilityPerformanceYearly[];
  facilitySolarPerformance: FacilitySolarPerformance[];
}

export const initialState: FacilityState = {
  facilityGroups: [],
  facilities: [],
  facility: undefined,
  facilityGroup: undefined,
  facilityPerformanceLoading: false,
  facilityPerformance: [],
  facilitySolarPerformance: [],
};

export const reducer = createReducer(
  initialState,
  on(loadFacilityById, (state): FacilityState => ({ ...state, facility: undefined })),
  on(loadFacilities, loadFacilitiesAtStartup, (state): FacilityState => ({ ...state, facilities: [] })),
  on(loadFacilitiesAtStartupSuccess, (state, { facilities, facilityGroups }): FacilityState => ({ ...state, facilities, facilityGroups })),
  on(loadFacilityByIdSuccess, (state, { facility }): FacilityState => ({ ...state, facility })),
  on(loadFacilityGroupsSuccess, (state, { facilityGroups }): FacilityState => ({ ...state, facilityGroups })),
  on(loadFacilitiesSuccess, (state, { facilities }): FacilityState => ({ ...state, facilities })),
  on(loadFacilityGroupEuiForYearAndFacilityGroupIdSuccess, (state, { facilityGroup }): FacilityState => ({ ...state, facilityGroupEui: facilityGroup.eui })),
  on(loadFacilityPerformancesBuildingByFacilityGroupId, (state): FacilityState => ({ ...state, facilityPerformance: [], facilityPerformanceLoading: true })),
  on(
    loadFacilityPerformancesBuildingByFacilityGroupIdSuccess,
    (state, { facilityGroupPerformance }): FacilityState => ({ ...state, facilityPerformance: facilityGroupPerformance, facilityPerformanceLoading: false }),
  ),
  on(
    loadFacilityPerformancesBuildingByFacilityGroupIdError,
    (state): FacilityState => ({ ...state, facilityPerformance: [], facilityPerformanceLoading: false }),
  ),
  on(loadFacilityPerformanceBuildingByFacilityId, (state): FacilityState => ({ ...state, facilityPerformance: [], facilityPerformanceLoading: true })),
  on(
    loadFacilityPerformanceBuildingByFacilityIdSuccess,
    (state, { facilityPerformance }): FacilityState => ({ ...state, facilityPerformance: [facilityPerformance], facilityPerformanceLoading: false }),
  ),
  on(loadFacilityPerformanceBuildingByFacilityIdError, (state): FacilityState => ({ ...state, facilityPerformance: [], facilityPerformanceLoading: false })),
  on(
    loadFacilityPerformanceSolarByFacilityGroupId,
    loadFacilityPerformanceSolarByFacilityId,
    (state): FacilityState => ({ ...state, facilitySolarPerformance: [], facilityPerformanceLoading: true }),
  ),
  on(
    loadFacilityPerformanceSolarByFacilityGroupIdSuccess,
    loadFacilityPerformanceSolarByFacilityIdSuccess,
    (state, { facilitySolarPerformance }): FacilityState => ({ ...state, facilitySolarPerformance, facilityPerformanceLoading: false }),
  ),
  on(
    loadFacilityPerformanceSolarByFacilityGroupIdError,
    loadFacilityPerformanceSolarByFacilityIdError,
    (state): FacilityState => ({ ...state, facilitySolarPerformance: [], facilityPerformanceLoading: false }),
  ),
  on(updateFacilities, (state, { facilities }): FacilityState => ({ ...state, facilities })),
  on(setCurrentFacilityGroup, (state, { facilityGroup }): FacilityState => ({ ...state, facilityGroup })),
  on(clearCurrentFacilityGroup, (state): FacilityState => ({ ...state, facilityGroup: undefined })),
  on(
    setCurrentGroupAndFacilityByIds,
    (state, { facilityGroupId, facilityId }): FacilityState => ({
      ...state,
      facilityGroup: state.facilityGroups.find(f => f.id === facilityGroupId),
      facility: state.facilities.find(f => f.id === facilityId),
    }),
  ),
  on(
    setCurrentFacilityGroupById,
    (state, { facilityGroupId }): FacilityState => ({
      ...state,
      facilityGroup: findFacilityGroupById(facilityGroupId, state.facilityGroups),
      facility: undefined,
    }),
  ),
  on(
    setCurrentFacilityById,
    (state, { facilityId }): FacilityState => ({
      ...state,
      facility: state.facilities.find(f => f.id === facilityId),
    }),
  ),
  on(updateFacilityAlertWithSnooze, (state, { alertId, snoozedUntil }) => {
    return {
      ...state,
      facilities: state.facilities.map(facility => {
        const alertExistsInFacility = facility.alerts.some(alert => alert.id === alertId);

        if (!alertExistsInFacility) {
          return facility;
        }

        const updatedAlerts = facility.alerts.map(alert => {
          if (alert.id !== alertId) {
            return alert;
          }

          return { ...alert, snoozedUntil };
        });

        return { ...facility, alerts: updatedAlerts };
      }),
    };
  }),
);

export const findFacilityGroupById = (id: number, facilityGroups: FacilityGroup[]): FacilityGroup | undefined => {
  for (const group of facilityGroups) {
    if (group.id === id) {
      return group;
    }
    if (group.subGroups.length) {
      const subgroup = findFacilityGroupById(id, group.subGroups);
      if (subgroup) {
        return subgroup;
      }
    }
  }
  return undefined;
};
