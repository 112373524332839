import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLoginGuard } from 'guards/auths/auth-login/auth-login.guard';
import { AuthPortalGuard } from 'guards/auths/auth-portal/auth-portal.guard';

export const routes: Routes = [
  {
    path: 'auth',
    canActivate: [AuthLoginGuard],
    loadChildren: () => import('./brain-auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthPortalGuard],
    loadChildren: () => import('./brain-dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'portal',
    canActivate: [AuthPortalGuard],
    loadChildren: () => import('./brain-portal/portal.module').then(m => m.PortalModule),
  },
  {
    path: 'public',
    loadChildren: () => import('./brain-public/public.module').then(m => m.PublicModule),
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
