import { createAction, props } from '@ngrx/store';

export const initLoginSignIn = createAction('[Login] Init Sign-in');
export const initLoginForgotPassword = createAction('[Login] Init Forgot Password');

export const postLogin = createAction('[Login] Login User', props<{ username: string; password: string }>());
export const postLoginSuccess = createAction('[Login] Login User success', props<{ userAuthenticated: any }>());
export const postLoginError = createAction('[Login] Login User error', props<{ error: Error }>());

export const loadUserAuthenticated = createAction('[User] Load User Authenticated');
export const loadUserAuthenticatedSuccess = createAction('[User] Load User Authenticated Success', props<{ userAuthenticated: any }>());
export const loadUserAuthenticatedError = createAction('[User] Load User Authenticated Error', props<{ error: Error }>());

export const postForgotPassword = createAction('[User] Forgot Password', props<{ email: string }>());
export const postForgotPasswordSuccess = createAction('[User] Forgot Password Success');
export const postForgotPasswordError = createAction('[User] Forgot Password Error', props<{ error: Error }>());
