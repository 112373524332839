import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FacilityGroup } from '../../model/facility-group.model';
import { Facility } from 'brain-data/model/facility.model';
import { DatedValue, Utility, UtilitySummary } from 'brain-data/model/utility.model';
import { UtilityTransformService } from '../utility/utility-transform.service';

export const FACILITY_GROUPS_URL = `${environment.apiBaseUrl}/facilityGroups`;

@Injectable({
  providedIn: 'root',
})
export class FacilityGroupService {
  constructor(private http: HttpClient, private utilityTransformService: UtilityTransformService) {}

  getFacilityGroups(): Observable<FacilityGroup[]> {
    return this.http.get<FacilityGroup[]>(FACILITY_GROUPS_URL);
  }

  getFacilitiesByFacilityGroupId(facilityGroupId: number): Observable<Facility[]> {
    return this.http.get<Facility[]>(`${FACILITY_GROUPS_URL}/${facilityGroupId}/facilities`);
  }

  getFacilityGroupEuiBYFacilityGroupIdAndYear(euiYear: number, facilityGroupId: number): Observable<FacilityGroup> {
    const params = new HttpParams({ fromObject: { euiYear } });
    return this.http.get<FacilityGroup>(`${FACILITY_GROUPS_URL}/${facilityGroupId}/eui`, { params });
  }

  getUtilitiesSummaryFacilityGroupId(facilityGroupId: number, startDate: string, endDate: string): Observable<Utility[]> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });

    return this.http
      .get<UtilitySummary>(`${FACILITY_GROUPS_URL}/${facilityGroupId}/utilities/summary`, { params })
      .pipe(map(utilitiesSummaryResponse => this.utilityTransformService.convertUtilitySummariesToUtilities(utilitiesSummaryResponse)));
  }

  getUtilitiesDetailByFacilityGroupId(facilityGroupId: number, startDate: string, endDate: string) {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });

    return this.http.get<
      Record<
        string,
        {
          usage: DatedValue[];
          spend: DatedValue[];
        }
      >
    >(`${FACILITY_GROUPS_URL}/${facilityGroupId}/utilities/detail`, { params });
  }

  getUtilitiesDetailsByFacilityGroupIdAndDateRange(facilityGroupId: number, startDate: string, endDate: string): Observable<Utility[]> {
    return this.getUtilitiesDetailByFacilityGroupId(facilityGroupId, startDate, endDate).pipe(
      map(utilitiesDetailsResponse => this.utilityTransformService.filterUtilitiesDetailByDateRange(utilitiesDetailsResponse)),
      map(utilitiesDetailsFilteredResponse => this.utilityTransformService.convertUtilityDetailsToUtilities(utilitiesDetailsFilteredResponse))
    );
  }

  gettUtilitiesYearlyByFacilityGroupIdAndDateRange(facilityGroupId: number, startDate: string, endDate: string): Observable<Utility[]> {
    return this.getUtilitiesDetailByFacilityGroupId(facilityGroupId, startDate, endDate).pipe(
      map(utilitiesDetailsResponse => this.utilityTransformService.filterUtilitiesDetailByDateRange(utilitiesDetailsResponse)),
      map(utilitiesDetailsFilteredResponse => this.utilityTransformService.convertUtilityDetailsToUtilities(utilitiesDetailsFilteredResponse)),
      map(utilities => this.utilityTransformService.filterUtilitiesToYearly(utilities))
    );
  }
}
