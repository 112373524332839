import {State} from '../../../reducers';
import {createSelector} from '@ngrx/store';
import {FacilityGroup} from '../../model/facility-group.model';
import {Facility} from '../../model/facility.model';
import {AlertStatus} from '../../model/alert.model';
import {FacilityPerformanceYearly} from 'brain-data/model/facility-performance.model';

export const selectFacilityData = (state: State) => state.facility;

export const selectFacilityGroups = createSelector(selectFacilityData, facilityState => facilityState.facilityGroups);
export const selectSortedFacilityGroups = createSelector(selectFacilityGroups, facilityGroups => {
  const sortedGroups = [...facilityGroups];
  sortedGroups.sort((a, b) => a.name.localeCompare(b.name));
  return sortedGroups;
});
export const selectFacilities = createSelector(selectFacilityData, facilityState => facilityState.facilities);

export const selectFacilitiesCount = createSelector(selectFacilities, facilities => facilities.length);
export const selectFacilitiesWithAlerts = createSelector(selectFacilities, facilities => facilities.filter(facility => facility.alertCount > 0));

export const selectFacilitiesWithOpenAlerts = createSelector(selectFacilitiesWithAlerts, facilities =>
  facilities
    .map(facility => {
      const alerts = facility.alerts.filter(alert => alert.status === AlertStatus.open);
      return { ...facility, alerts, alertCount: alerts.length };
    })
    .filter(facility => facility.alertCount > 0),
);
export const selectFacility = createSelector(selectFacilityData, facilityState => facilityState.facility);

export const selectFacilityGroup = createSelector(selectFacilityData, facilityState => facilityState.facilityGroup);
export const selectFacilityGroupEui = createSelector(selectFacilityData, facilityState => facilityState.facilityGroupEui);

export const selectFacilityPerformanceLoading = createSelector(selectFacilityData, facilityState => facilityState.facilityPerformanceLoading);
export const selectFacilityPerformance = createSelector(selectFacilityData, facilityState => facilityState.facilityPerformance);
export const selectFacilityPerformanceWithAggregatedYearEuis = createSelector(
  selectFacilityPerformance,
  (performances: FacilityPerformanceYearly[]): { [year: string]: number } => {
    const euiAccumulator: { [year: string]: { totalEui: number; count: number } } = {};

    performances.forEach(({ yearEuis }) => {
      yearEuis &&
        Object.entries(yearEuis).forEach(([year, eui]) => {
          if (!euiAccumulator[year]) {
            euiAccumulator[year] = { totalEui: 0, count: 0 };
          }
          if (eui > 0) {
            euiAccumulator[year].totalEui += eui;
            euiAccumulator[year].count += 1;
          }
        });
    });

    return Object.keys(euiAccumulator).reduce((acc: { [year: string]: number }, year) => {
      const {totalEui, count} = euiAccumulator[year];
      acc[year] = count > 0 ? totalEui / count : 0;
      return acc;
    }, {});
  },
);
export const selectAverageEUIExcludingZeros = createSelector(
  selectFacilityPerformanceWithAggregatedYearEuis,
  (aggregatedEuis: { [year: number]: number }): number => {
    let totalEUI = 0;
    let count = 0;

    Object.values(aggregatedEuis).forEach(eui => {
      if (eui > 0) {
        totalEUI += eui;
        count++;
      }
    });
    return count > 0 ? totalEUI / count : 0;
  },
);
export const selectFacilitySolarPerformance = createSelector(selectFacilityData, facilityState => facilityState.facilitySolarPerformance);

export const selectFacilitiesForGroup = createSelector(selectFacilities, selectFacilityGroup, (facilities, group) => filterFacilities(facilities, group));
export const selectFacilitiesForGroupOrFacility = createSelector(selectFacilitiesForGroup, selectFacility, (facilities, facility) => {
  if (facility) {
    return [facility];
  } else {
    return facilities;
  }
});

export const filterFacilities = (facilities: Facility[], group?: FacilityGroup) =>
  group ? facilities.filter(facility => facility.groups.includes(group.name)) : facilities;
